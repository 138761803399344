<template>
    <div>
        <div class="ft20 cl-main ftw600">权限组</div>

        <div class="mt20">
            <div class="bg-w">
                <a-layout>
                    <a-layout-header class="layout-classes-header">
                        <a-menu class="classes-menu" :defaultSelectedKeys="[selectCurrent]" @click="selectMenu" mode="horizontal">
                            <a-menu-item key="platform">平台权限组</a-menu-item>
<!--                            <a-menu-item key="shop">站点角色组</a-menu-item>-->
                        </a-menu>
                    </a-layout-header>
                    <a-layout-content class="layout-classes-content">
                        <div v-if="selectCurrent == 'platform'">
                            <platform-account></platform-account>
                        </div>
                        <div v-if="selectCurrent == 'shop'">
                            <shop-account></shop-account>
                        </div>

                    </a-layout-content>
                </a-layout>
            </div>
        </div>
    </div>
</template>

<script>
import shopAccount from './components/group/shop.vue';
import platformAccount from './components/group/platform.vue';
export default{
    components:{
        shopAccount,
        platformAccount,
    },
    data(){
        return {
            selectCurrent:'platform',
        }
    },
    methods:{
        selectMenu(e) {
            this.selectCurrent = e.key;
        },
    }
}
</script>

<style scoped>
.layout-classes-header {
    background: #FFFFFF;
}

.layout-classes-header {
    padding: 0 20px;
    height: 58px;
    line-height: 57px;
    border-bottom: 1px solid #F0F3F5;
    width: 100%;
}

.layout-classes-content {
    padding: 0px 0px 10px 0px;
    background: #FFFFFF;
    width: 100%;
    min-height: 700px;
}

.classes-menu {
    border-bottom: none;
    line-height: 55px;
    font-size: 14px;
}
</style>
